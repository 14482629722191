import { black, font, spaceS, spaceXxs } from 'styles/variables';
import styled from 'styled-components';

const Content = styled.div`
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: ${spaceS};
    margin: ${spaceS};
    color: ${font.color.grey};
    strong {
        font-weight: ${font.weight.bold};
        color: ${black};
    }
`;

const TooltipHeadline = styled.h1`
    font-size: ${font.size.l};
    line-height: ${font.lineHeight.l};
    margin-bottom: ${spaceS};
    margin-top: ${spaceXxs};
    color: ${font.color.default};
`;

const TooltipText = styled.p`
    font-size: ${font.size.s};
    margin: 0;
`;

type TooltipContentProp = {
    headline: string;
    icon: React.ReactNode;
    text?: string;
    content?: React.ReactNode;
};

function TooltipContent(props: TooltipContentProp): JSX.Element {
    const { headline, text, icon, content } = props;

    return (
        <Content>
            {icon}
            <div>
                <TooltipHeadline>{headline}</TooltipHeadline>
                {text && <TooltipText>{text}</TooltipText>}
                {content}
            </div>
        </Content>
    );
}

export default TooltipContent;
