import { ButtonSize, ButtonVariant, ECountry, EToastType } from 'types';
import { EBusinessFeature } from 'types/business';
import { EConnectorStatus, IProperty } from 'types/facility';
import { ETrackingOrigin, ETrackingType } from 'types/tracking';
import {
    borderRadiusM,
    borderRadiusS,
    dividerColor,
    errorColor,
    font,
    listHeaderBackgroundColor,
    listHoverBackgroundColor,
    listHoverBorderColor,
    listItemPadding,
    mediumGrey,
    modalWidthSmall,
    spaceS,
    spaceXs,
    spaceXxxs,
} from 'styles/variables';
import { getConnectorStatus } from 'utils/connectorStatus';
import { textEllipsis } from 'styles/mixins';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useModal } from 'contexts/Modal';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import Button from 'components/clickables/Button';
import CCSIcon from 'assets/icons/connectors/ic_ccs.svg?react';
import ChademoIcon from 'assets/icons/connectors/ic_chademo.svg?react';
import ConnectorStatus from './ConnectorStatus';
import EmptyListMessage from 'components/info/EmptyListMessage';
import MinusIcon from 'assets/icons/ic_minus.svg?react';
import PlusIcon from 'assets/icons/ic_plus.svg?react';
import PopUp from 'components/info/PopUp';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import ResetChargerModal from './ResetChargerModal';
import TextEllipsisComponent from 'components/info/TextEllipsisComponent';
import TooltipContent from 'components/info/TooltipContent';
import Type1Icon from 'assets/icons/connectors/ic_type1.svg?react';
import Type2Icon from 'assets/icons/connectors/ic_type2.svg?react';
import WarningIcon from 'assets/icons/ic_warning_solid.svg?react';
import styled from 'styled-components';
import useAuthorization from 'hooks/useAuthorization';
import useBUContent from 'hooks/useBUContent';
import useLocalState from 'hooks/useLocalState';
import useResetCharger from 'hooks/useResetCharger';

const headerHight = '3.5rem';
const sectionHeaderHight = '48px';
const listItemHight = '3.5rem';
const listContentGap = '3.5rem';
const listBorder = `
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
`;

const Property = styled.section`
    border-radius: ${borderRadiusM};
    border: 1px solid ${dividerColor};
    margin-bottom: ${spaceS};
    overflow: hidden;
`;

const ListHeader = styled.h2`
    height: ${headerHight};
    background-color: ${listHeaderBackgroundColor};
    font-weight: ${font.weight.bold};
    padding: ${listItemPadding};
    display: flex;
    align-items: center;
    line-height: ${font.lineHeight.l};
    span {
        ${textEllipsis}
    }
`;

const List = styled.ol`
    > li > button:hover {
        background-color: ${listHoverBackgroundColor};
        border-top-color: ${listHoverBorderColor};
    }
    > li:not(:last-child) > button:hover,
    > li:last-child > button:hover:has(+ ol.expanded) {
        border-bottom-color: ${listHoverBorderColor};
    }
    > li:hover + li > button {
        border-top-color: transparent;
    }

    > li + li > button,
    > li > button:has(+ ol.expanded) + li > button,
    > li:hover:has(> ol.expanded) + li > button {
        border-top-color: ${dividerColor};
    }
`;

const SiteName = styled.button`
    display: grid;
    grid-template-columns: 1.5rem auto 1.25rem;
    height: ${listItemHight};
    align-items: center;
    justify-content: flex-start;
    padding: ${listItemPadding};
    gap: ${spaceS};
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.m};
    font-family: ${font.body};
    text-align: left;
    background: none;
    cursor: pointer;
    width: 100%;
    user-select: text;
    ${listBorder}
    span {
        ${textEllipsis}
    }
`;

const ChargerList = styled.ol`
    position: relative;
    margin-left: 3.625rem;
    &:before {
        content: '';
        position: absolute;
        top: 1.625rem;
        left: -1.688rem;
        width: 0.938rem;
        height: 0.063rem;
        background-color: ${mediumGrey};
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -1.688rem;
        width: 0.063rem;
        height: 1.688rem;
        background-color: ${mediumGrey};
    }
    &.collapsed {
        display: none;
    }
    &.expanded {
        display: block;
    }

    > li > div:hover {
        background-color: ${listHoverBackgroundColor};
        border-top-color: ${listHoverBorderColor};
    }
    > li:not(:last-child) > div:hover,
    > li:last-child > div:hover:has(+ ol.expanded) {
        border-bottom-color: ${listHoverBorderColor};
    }
    > li:hover + li > div {
        border-top-color: transparent;
    }

    > li + li > div,
    > li > div:has(+ ol.expanded) + li > div,
    > li:hover:has(> ol.expanded) + li > div {
        border-top-color: ${dividerColor};
    }
`;
const SectionList = styled.ol`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 1.625rem;
        left: -1.688rem;
        width: 0.938rem;
        height: 0.063rem;
        background-color: ${mediumGrey};
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -1.688rem;
        width: 0.063rem;
        height: 1.688rem;
        background-color: ${mediumGrey};
    }
    margin-left: 3.625rem;
    &.collapsed {
        display: none;
    }
    &.expanded {
        display: block;
    }

    > li {
        ${listBorder}
    }
    > li:not(:first-child):hover {
        background-color: ${listHoverBackgroundColor};
        border-top-color: ${listHoverBorderColor};
    }
    > li:not(:first-child):not(:last-child) {
        border-bottom-color: ${dividerColor};
        &:hover {
            border-bottom-color: ${listHoverBorderColor};
        }
        &:has(+ li:hover) {
            border-bottom-color: transparent;
        }
    }
`;

const listHeaderStyling = `
    font-weight: ${font.weight.bold};
    background-color: ${listHeaderBackgroundColor};
    display: grid;
    padding: ${listItemPadding};
    align-items: center;
    align-items: center;
    gap: ${listContentGap};
    height: ${sectionHeaderHight};
    span {
        ${textEllipsis}
    }
`;

const listItemStyling = `
    display: grid;
    gap: ${listContentGap};
    height: ${listItemHight};
    padding: ${listItemPadding};
    background: none;
    border:none;
    align-items: center;
    font-size: ${font.size.m};
    font-family: ${font.body};
    text-align: left;
`;

const chargerlistColumns = 'minmax(2.5rem, 25rem) minmax(2.5rem, 13rem) minmax(8rem, 1fr)';
const connectorlistColumns = 'minmax(2.5rem,6.875rem) minmax(2.5rem, 11rem) minmax(2.5rem, 1fr) 11rem';

const ChargerListHeader = styled.li`
    ${listHeaderStyling}
    &.showEquipmentId {
        grid-template-columns: ${chargerlistColumns} 11rem;
    }
    &.hideEquipmentId {
        grid-template-columns: minmax(2.5rem, 27rem) minmax(8rem, 1fr) 11rem;
    }
`;

const ConnectorListHeader = styled.li`
    ${listHeaderStyling}
    &.showEquipmentId {
        grid-template-columns: ${connectorlistColumns};
    }
    &.hideEquipmentId {
        grid-template-columns: minmax(2.5rem, 6.875rem) minmax(2.5rem, 13rem) minmax(2.5rem, 1fr) 11rem;
    }
`;

const ChargerListItem = styled.div`
    ${listItemStyling}
    ${listBorder}
    grid-template-columns: 1fr 11rem;
    cursor: pointer;
    width: 100%;
`;

const ConnectorListItem = styled.li`
    ${listItemStyling}
    &.showEquipmentId {
        grid-template-columns: ${connectorlistColumns};
    }
    &.hideEquipmentId {
        grid-template-columns: minmax(2.5rem, 6.875rem) minmax(2.5rem, 13rem) minmax(2.5rem, 1fr) 11rem;
    }
    span.faulted {
        background-color: ${errorColor};
        border-radius: ${borderRadiusS};
        text-align: center;
        color: white;
        padding: ${spaceXxxs} ${spaceXs};
        width: max-content;
    }
`;

const ResetContainer = styled.span`
    display: flex;
    align-items: center;
    gap: ${spaceXs};
`;

const ToggleButton = styled.button`
    ${listItemStyling}
    cursor: pointer;
    display: grid;
    padding: 0;
    user-select: text;
    &.showEquipmentId {
        grid-template-columns: ${chargerlistColumns};
    }
    &.hideEquipmentId {
        grid-template-columns: minmax(2.5rem, 27rem) minmax(8rem, 1fr);
    }
`;

const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: 1.5rem minmax(2rem, auto);
    gap: ${spaceS};
    align-items: center;
`;

const ResetButtonContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1.25rem;
    gap: 0.75rem;
    align-items: center;
`;

const CustomerServiceDescription = styled.div`
    font-size: ${font.size.s};
    font-weight: ${font.weight.regular};
    font-family: ${font.body};
    line-height: ${font.lineHeight.l};
    margin-top: ${spaceS};
`;

const Link = styled.a`
    display: block;
    width: fit-content;
    text-decoration: none;
    margin-bottom: ${spaceXs};
    word-break: break-all;
`;

type PropertyListProps = {
    properties: IProperty[];
    expandedItems?: string[];
    toggleSection: (id: string) => void;
};

const connectorIcon = {
    CCS: <CCSIcon />,
    CHADEMO: <ChademoIcon />,
    TYPE_1: <Type1Icon />,
    TYPE_2: <Type2Icon />,
};

function PropertyList({ properties, expandedItems, toggleSection }: PropertyListProps): JSX.Element {
    const { t } = useTranslation();
    const { checkAuthorizationFeatureForBusiness } = useAuthorization();
    const { countryCode } = useCountrySpecificContent();
    const { buContent } = useBUContent();

    const {
        state: { connectorState },
    } = useLocalState();
    const { close, open, setModalWidth, lockModal, setCloseButtonVisible } = useModal();
    const { resetCharger } = useResetCharger();
    const { addToast } = useToast();

    const resetChargerOnClick = async (identityKey: string): Promise<void> => {
        close();
        await resetCharger
            .mutateAsync(identityKey)
            .then(() => addToast({ message: t('facility.management.toast.success'), type: EToastType.SUCCESS }))
            .catch(() => addToast({ message: t('facility.management.toast.error'), type: EToastType.ERROR }));
    };

    const onResetPressed = (identityKey: string): void => {
        setModalWidth(modalWidthSmall);
        lockModal(true);
        setCloseButtonVisible(false);
        open(<ResetChargerModal onResetClick={() => resetChargerOnClick(identityKey)} onCancelClick={close} />);
    };

    if (properties.length < 1) {
        return <EmptyListMessage displayBorder />;
    }

    const showEquipmentId = countryCode === ECountry.DE;

    return (
        <>
            {properties.map((property) => (
                <Property key={property.name}>
                    <ListHeader data-testid={`propertyListHeader_${property.name}`}>
                        <span>{property.name}</span>
                    </ListHeader>
                    <List data-testid={`siteList_${property.name}`}>
                        {property.sites.map((site) => {
                            const expandedSite = expandedItems?.includes(site.id);
                            const markSiteWithError = site.chargers.some((charger) => {
                                return charger.connectors.some((connector) => {
                                    return (
                                        getConnectorStatus(connectorState, connector.evseId)?.status ===
                                        EConnectorStatus.FAULTED
                                    );
                                });
                            });

                            return (
                                <li key={site.id} data-testid={site.id}>
                                    <SiteName
                                        onClick={() => toggleSection(site.id)}
                                        data-testid={`toggleButton_${site.id}`}
                                    >
                                        {expandedSite ? <MinusIcon /> : <PlusIcon />}
                                        <span>{site.name}</span>
                                        {markSiteWithError ? <WarningIcon /> : ''}
                                    </SiteName>
                                    <ChargerList
                                        className={expandedSite ? 'expanded' : 'collapsed'}
                                        data-testid={`chargerList_${site.id}`}
                                    >
                                        <ChargerListHeader
                                            className={showEquipmentId ? 'showEquipmentId' : 'hideEquipmentId'}
                                        >
                                            <span>{t('facility.charger.model')}</span>
                                            {showEquipmentId && <span>{t('facility.charger.equipmentId')}</span>}
                                            <span>{t('facility.charger.serialNumber')}</span>
                                            <ResetContainer>
                                                {t('general.reset')}
                                                <PopUp
                                                    dataTestId="resetInfo"
                                                    gaTracking={{
                                                        type: ETrackingType.RESET_INFO,
                                                        origin: ETrackingOrigin.FACILITY_MANAGEMENT,
                                                    }}
                                                    content={
                                                        <TooltipContent
                                                            headline={t('facility.management.resetInfoTitle')}
                                                            text={t('facility.management.resetInfoDescription')}
                                                            icon={<QuestionMarkTooltipIcon />}
                                                            content={
                                                                <>
                                                                    <CustomerServiceDescription>
                                                                        {`${t(
                                                                            'facility.management.connectorStatus.description',
                                                                        )}`}
                                                                    </CustomerServiceDescription>
                                                                    <Link
                                                                        href={`tel:${buContent.customerService.phoneNumer}`}
                                                                    >
                                                                        {buContent.customerService.phoneNumer}
                                                                    </Link>
                                                                </>
                                                            }
                                                        />
                                                    }
                                                />
                                            </ResetContainer>
                                        </ChargerListHeader>
                                        {site.chargers.map((charger) => {
                                            const chargerNeedsReset = charger.connectors.some((connector) => {
                                                return (
                                                    getConnectorStatus(connectorState, connector.evseId)?.status ===
                                                    EConnectorStatus.FAULTED
                                                );
                                            });

                                            const expanded = expandedItems?.includes(charger.serialNumber);
                                            const canResetCharger = checkAuthorizationFeatureForBusiness(
                                                EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
                                                charger.businessId,
                                            );

                                            return (
                                                <li
                                                    key={charger.serialNumber}
                                                    data-testid={`charger_${charger.serialNumber}`}
                                                >
                                                    <ChargerListItem
                                                        className={`${expanded ? 'expanded' : 'collapsed'}`}
                                                    >
                                                        <ToggleButton
                                                            data-testid={`toggleButton_${charger.serialNumber}`}
                                                            onClick={() => toggleSection(charger.serialNumber)}
                                                            className={
                                                                showEquipmentId ? 'showEquipmentId' : 'hideEquipmentId'
                                                            }
                                                        >
                                                            <FlexContainer>
                                                                {expanded ? <MinusIcon /> : <PlusIcon />}
                                                                <TextEllipsisComponent tooltipText={charger.model}>
                                                                    {charger.model}
                                                                </TextEllipsisComponent>
                                                            </FlexContainer>
                                                            {showEquipmentId && (
                                                                <TextEllipsisComponent
                                                                    tooltipText={charger.equipmentId}
                                                                >
                                                                    {charger.equipmentId}
                                                                </TextEllipsisComponent>
                                                            )}
                                                            <TextEllipsisComponent tooltipText={charger.serialNumber}>
                                                                {charger.serialNumber}
                                                            </TextEllipsisComponent>
                                                        </ToggleButton>
                                                        {canResetCharger && (
                                                            <ResetButtonContainer>
                                                                <Button
                                                                    dataTestId={`resetButton_${charger.identityKey}`}
                                                                    variant={ButtonVariant.SECONDARY}
                                                                    size={ButtonSize.SMALL}
                                                                    onClick={() => onResetPressed(charger.identityKey)}
                                                                >
                                                                    {t('general.reset')}
                                                                </Button>
                                                                {chargerNeedsReset ? <WarningIcon /> : null}
                                                            </ResetButtonContainer>
                                                        )}
                                                    </ChargerListItem>
                                                    <SectionList
                                                        className={expanded ? 'expanded' : 'collapsed'}
                                                        data-testid={`connectorList_${charger.serialNumber}`}
                                                    >
                                                        <ConnectorListHeader
                                                            className={
                                                                showEquipmentId ? 'showEquipmentId' : 'hideEquipmentId'
                                                            }
                                                        >
                                                            <span>{t('facility.connector.connectorId')}</span>
                                                            <span>{t('facility.connector.evseId')}</span>
                                                            <span>{t('general.type')}</span>
                                                            <span>{t('general.status')}</span>
                                                        </ConnectorListHeader>
                                                        {charger.connectors.map((connector) => {
                                                            const icon = connectorIcon[
                                                                connector.type as keyof typeof connectorIcon
                                                            ] ?? <span className="noIcon" />;

                                                            const connectorStatus = getConnectorStatus(
                                                                connectorState,
                                                                connector.evseId,
                                                            );

                                                            return (
                                                                <ConnectorListItem
                                                                    key={connector.evseId}
                                                                    data-testid={`connector_${connector.evseId}`}
                                                                    className={
                                                                        showEquipmentId
                                                                            ? 'showEquipmentId'
                                                                            : 'hideEquipmentId'
                                                                    }
                                                                >
                                                                    <FlexContainer>
                                                                        {icon}
                                                                        {connector.connectorId}
                                                                    </FlexContainer>
                                                                    <TextEllipsisComponent
                                                                        tooltipText={connector.evseId}
                                                                    >
                                                                        {connector.evseId}
                                                                    </TextEllipsisComponent>

                                                                    <span>{connector.type}</span>
                                                                    <ConnectorStatus status={connectorStatus?.status} />
                                                                </ConnectorListItem>
                                                            );
                                                        })}
                                                    </SectionList>
                                                </li>
                                            );
                                        })}
                                    </ChargerList>
                                </li>
                            );
                        })}
                    </List>
                </Property>
            ))}
        </>
    );
}

export default PropertyList;
